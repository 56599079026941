<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="판심 소개" tabActive="대표변호사 소개">
                <template #titHead>R</template>
                <template #tit>e : V</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first">
            <v-row align="center" class="flex-md-row-reverse">
                <v-col cols="12" md="7">
                    <div class="pl-md-62px">
                        <div class="dome">
                            <div data-aos="zoom-out" class="dome__bg"></div>
                            <v-img data-aos="zoom-out" data-aos-delay="200" src="/images/sub/rev/intro/intro-img2.jpg" max-width="580" :aspect-ratio="580 / 758" class="dome__img" />
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="5">
                    <v-img data-aos="fade-up" src="/images/sub/rev/intro/intro-img.svg" max-width="692" :aspect-ratio="692 / 136" :min-width="$vuetify.breakpoint.lgAndUp ? 692 : ''" />
                    <u-txt-default data-aos="fade-up" data-aos-delay="200" class="line-height-17 mt-40px mt-md-60px mt-lg-70px">
                        <p>
                            2010년 개설한 ‘판사의 마음’ 블로그에 내가 처음 글을 쓰기 시작한 것은 <br class="d-none d-xl-block">
                            나의 법정에서 재판을 받는 내 재판의 당사자들에게 법대에 앉은 판사의 <br class="d-none d-xl-block">
                            마음을 담담히 말해주고 싶어서였다.
                        </p>
                        <p>
                            판사가 법정에서는 차마 다 말로 하지 못했던 말들, <br class="d-none d-lg-block">
                            판사가 판결문에는 빼곡히 담지 못한 마음들.
                        </p>
                        <p>
                            당사자가 받는 판결문은 몇 장에 불과할지라도, 그 속에 담긴 판사의 마음은 <br class="d-none d-xl-block">
                            항상 수천 장이었다. 처음 판사가 받아 들고 검토하게 되는 소장, 답변서, <br class="d-none d-xl-block">
                            준비서면, 고소장, 공소장, 변호인 의견서들.
                        </p>
                    </u-txt-default>
                </v-col>
            </v-row>
        </page-section-primary>

        <page-section-primary class="pt-0">
            <v-row>
                <v-col cols="12" md="5">
                    <u-tit-default data-aos="fade-up" class="font-primary line-height-15">
                        여기부터 판사의 고심은, <br />
                        그 머나먼 여정이 시작된다.
                    </u-tit-default>
                </v-col>
                <v-col cols="12" md="7">
                    <div class="pl-md-62px">
                        <u-txt-default data-aos="fade-up" data-aos-delay="200" class="line-height-17">
                            <p>
                                한 사건의 판결을 선고하기까지. 아니, 정확히는 판결을 선고하고 나서가 끝이 아니다. <br />
                                오히려 판결선고 당시에는 결론에 확신이 있었는데, 선고 이후 ‘그때 내 판단은 맞는 것이었을까’ <br class="d-none d-xl-block">
                                라는 의문이 주심판사의 머릿속에서 떠나지 않는 사건도 있다.
                            </p>
                            <p>
                                그렇게 선고 이후에도 판단에 대한 스스로의 의문이 사라지는 알 수 없는 미래의 순간까지, <br class="d-none d-xl-block">
                                주심판사의 고심은 이어진다.
                            </p>
                            <p>
                                첫 임관 후 초임 시절 미숙하게 써 내려갔던 판결문의 결론, 구성, 고심했던 문구, 단어. <br class="d-none d-lg-block">
                                판사라면, 평생 자신의 마음 속에 남아 있지 않을까.
                            </p>
                        </u-txt-default>
                    </div>
                </v-col>
            </v-row>
        </page-section-primary>

        <page-section-primary class="pt-0">
            <template #pageSectionHead>
                <v-img data-aos="fade-up" src="/images/sub/rev/intro/intro-img3.jpg" max-width="1920" :aspect-ratio="1920 / 500" class="mx-auto" />
            </template>
            <u-txt-default data-aos="fade-up" data-aos-delay="200" class="line-height-17 mt-40px mt-lg-80px">
                <p>
                    변호사들의 수많은 준비서면, 의견서를 받아 들고 판사가 어떤 생각을 하기 시작하는지, 그 서면을 검토하는 과정, 첨예하게 다투는 주장 중 어떤 주장에 마음이 쏠리기 시작하는지, <br class="d-none d-xl-block">
                    그 주장을 뒷받침하는 증거 중 어떤 증거의 가치를 더 높다고 판단하게 되는지, 법정에서 기일을 지정하는 방식, 기일을 갑자기 연기하게 되는 사유, 어떤 증인에 대하여는 증인신청을 받아 주는 <br class="d-none d-xl-block">
                    반면에 어떤 증인에 대하여는 신청을 기각하게 되는지, 또 최종 결론을 내는 재판부 합의과정은 언제 거치게 되는지, 판결문은 언제 쓰는지, 판결문을 쓰고 난 이후 결론을 고치지는 않는지. 
                </p>
                <p>
                    결국 이러한 과정들을 거쳐 판결 선고기일, 판사가 법대에서 구술로 선고를 하는 마지막 순간까지.
                </p>
                <p>
                    주심판사는 출근하는 길에도, 밥을 먹을 때도, 잠자리에 들어서도, 쉬지 않고 사건의 사실관계를 상상하고, 법리를 고심하며, 결론을 고민한다. 자다가 또는 씻다가 생각난 판결문 문구를 급하게 <br class="d-none d-xl-block">
                    핸드폰 메모장에 적는 일은 판사라면 숱한 일이다. 이런 이성적 판단에 더해 내 판결의 결론이 당사자와 피해자의 인생에 미칠 영향, 나아가 내 판결이 선례가 되어 파장을 미칠지도 모르는 <br class="d-none d-xl-block">
                    사회적 영향까지 생각하지 않을 수 없다.
                </p>
                <p>
                    판사 시절부터 작성해온 나만의 “판사의 마음” 블로그는 그야말로 이런 고심에 고심을 거듭하여 사건의 결론을 내리고 판결문 단어 하나도 고르고 골라 쓰는 판사의 마음을 진솔하게 써 나갔던, <br class="d-none d-xl-block">
                    나의 애정이 담긴 그것이었다. 그러던 블로그가 어느새 13년, 사람으로 치면 중학생이 되었고 그 세월이 흐르는 동안 나는,
                </p>
            </u-txt-default>
            <u-tit-default data-aos="fade-up" data-aos-delay="200" class="line-height-15 font-primary mt-30px mt-md-60px">
                2022년. <br />
                <span class="primary--text text--lighten-3">법복을 벗고 새로운 삶을 시작하였다.</span>
            </u-tit-default>
        </page-section-primary>

        <page-section-primary class="page-section--last" style="background-image: url(/images/sub/rev/intro/intro-bg.jpg)">
            <v-sheet>
                <div class="pa-20px px-md-0 py-md-30px py-lg-60px">
                    <v-row align="center">
                        <v-col cols="12" md="6" order="2" order-md="1" class="mt-20px mt-md-0">
                            <v-img data-aos="zoom-out" src="/images/sub/rev/intro/intro-img4.svg" max-width="586" :aspect-ratio="586 / 406" class="ml-auto" />
                        </v-col>
                        <v-col cols="12" md="6" order="1" order-md="2">
                            <div class="pr-md-30px pr-lg-80px">
                                <u-txt-default data-aos="fade-up" class="line-height-17">
                                    <p>
                                        판사의 마음으로 오랜 시간 생활해 온 나에게 <br class="d-none d-lg-block">
                                        누군가가 변호사가 된 나의 마음이 얼마나 달라졌느냐고 묻는다면,
                                    </p>
                                    <p>
                                        이렇게 대답해주고 싶다.
                                    </p>
                                    <p>
                                        내가 가졌던 판사의 마음은 다른 그 누구도 아닌, “나의 판사의 마음”이었고, <br class="d-none d-xl-block">
                                        내 마음 속의 “나만의 판사의 마음”이 얼마나 살아있는지 나 또한 궁금하다고,
                                    </p>
                                </u-txt-default>
                                <u-tit-default data-aos="fade-up" data-aos-delay="200" class="line-height-15 font-primary mt-30px mt-md-60px">
                                    그런 마음으로 나의 사무실 “판사의 마음” <br />
                                    <span class="primary--text text--lighten-3">즉, 판심을 연다.</span>
                                </u-tit-default>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-sheet>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.dome{
    --dome-padding: 20px;
    max-width: 628px;
    position: relative;
    padding: var(--dome-padding) var(--dome-padding) 0 0;
    &__bg{
        position: absolute;
        z-index: -1;
        width: calc(100% - var(--dome-padding));
        height: calc(100% - var(--dome-padding));
        background-color: var(--v-primary-lighten2);
        top: 0;
        right: 0;
    }
    &__bg,
    &__img{
        border-radius: 999px 999px 0 0;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .dome{
        --dome-padding: 48px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
