var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "판심 소개",
            "tabActive": "대표변호사 소개"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("R")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("e : V")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first"
  }, [_c('v-row', {
    staticClass: "flex-md-row-reverse",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pl-md-62px"
  }, [_c('div', {
    staticClass: "dome"
  }, [_c('div', {
    staticClass: "dome__bg",
    attrs: {
      "data-aos": "zoom-out"
    }
  }), _c('v-img', {
    staticClass: "dome__img",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": "/images/sub/rev/intro/intro-img2.jpg",
      "max-width": "580",
      "aspect-ratio": 580 / 758
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "data-aos": "fade-up",
      "src": "/images/sub/rev/intro/intro-img.svg",
      "max-width": "692",
      "aspect-ratio": 692 / 136,
      "min-width": _vm.$vuetify.breakpoint.lgAndUp ? 692 : ''
    }
  }), _c('u-txt-default', {
    staticClass: "line-height-17 mt-40px mt-md-60px mt-lg-70px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('p', [_vm._v(" 2010년 개설한 ‘판사의 마음’ 블로그에 내가 처음 글을 쓰기 시작한 것은 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 나의 법정에서 재판을 받는 내 재판의 당사자들에게 법대에 앉은 판사의 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 마음을 담담히 말해주고 싶어서였다. ")]), _c('p', [_vm._v(" 판사가 법정에서는 차마 다 말로 하지 못했던 말들, "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 판사가 판결문에는 빼곡히 담지 못한 마음들. ")]), _c('p', [_vm._v(" 당사자가 받는 판결문은 몇 장에 불과할지라도, 그 속에 담긴 판사의 마음은 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 항상 수천 장이었다. 처음 판사가 받아 들고 검토하게 되는 소장, 답변서, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 준비서면, 고소장, 공소장, 변호인 의견서들. ")])])], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "pt-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('u-tit-default', {
    staticClass: "font-primary line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 여기부터 판사의 고심은, "), _c('br'), _vm._v(" 그 머나먼 여정이 시작된다. ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pl-md-62px"
  }, [_c('u-txt-default', {
    staticClass: "line-height-17",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('p', [_vm._v(" 한 사건의 판결을 선고하기까지. 아니, 정확히는 판결을 선고하고 나서가 끝이 아니다. "), _c('br'), _vm._v(" 오히려 판결선고 당시에는 결론에 확신이 있었는데, 선고 이후 ‘그때 내 판단은 맞는 것이었을까’ "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 라는 의문이 주심판사의 머릿속에서 떠나지 않는 사건도 있다. ")]), _c('p', [_vm._v(" 그렇게 선고 이후에도 판단에 대한 스스로의 의문이 사라지는 알 수 없는 미래의 순간까지, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 주심판사의 고심은 이어진다. ")]), _c('p', [_vm._v(" 첫 임관 후 초임 시절 미숙하게 써 내려갔던 판결문의 결론, 구성, 고심했던 문구, 단어. "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 판사라면, 평생 자신의 마음 속에 남아 있지 않을까. ")])])], 1)])], 1)], 1), _c('page-section-primary', {
    staticClass: "pt-0",
    scopedSlots: _vm._u([{
      key: "pageSectionHead",
      fn: function () {
        return [_c('v-img', {
          staticClass: "mx-auto",
          attrs: {
            "data-aos": "fade-up",
            "src": "/images/sub/rev/intro/intro-img3.jpg",
            "max-width": "1920",
            "aspect-ratio": 1920 / 500
          }
        })];
      },
      proxy: true
    }])
  }, [_c('u-txt-default', {
    staticClass: "line-height-17 mt-40px mt-lg-80px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('p', [_vm._v(" 변호사들의 수많은 준비서면, 의견서를 받아 들고 판사가 어떤 생각을 하기 시작하는지, 그 서면을 검토하는 과정, 첨예하게 다투는 주장 중 어떤 주장에 마음이 쏠리기 시작하는지, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 그 주장을 뒷받침하는 증거 중 어떤 증거의 가치를 더 높다고 판단하게 되는지, 법정에서 기일을 지정하는 방식, 기일을 갑자기 연기하게 되는 사유, 어떤 증인에 대하여는 증인신청을 받아 주는 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 반면에 어떤 증인에 대하여는 신청을 기각하게 되는지, 또 최종 결론을 내는 재판부 합의과정은 언제 거치게 되는지, 판결문은 언제 쓰는지, 판결문을 쓰고 난 이후 결론을 고치지는 않는지. ")]), _c('p', [_vm._v(" 결국 이러한 과정들을 거쳐 판결 선고기일, 판사가 법대에서 구술로 선고를 하는 마지막 순간까지. ")]), _c('p', [_vm._v(" 주심판사는 출근하는 길에도, 밥을 먹을 때도, 잠자리에 들어서도, 쉬지 않고 사건의 사실관계를 상상하고, 법리를 고심하며, 결론을 고민한다. 자다가 또는 씻다가 생각난 판결문 문구를 급하게 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 핸드폰 메모장에 적는 일은 판사라면 숱한 일이다. 이런 이성적 판단에 더해 내 판결의 결론이 당사자와 피해자의 인생에 미칠 영향, 나아가 내 판결이 선례가 되어 파장을 미칠지도 모르는 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 사회적 영향까지 생각하지 않을 수 없다. ")]), _c('p', [_vm._v(" 판사 시절부터 작성해온 나만의 “판사의 마음” 블로그는 그야말로 이런 고심에 고심을 거듭하여 사건의 결론을 내리고 판결문 단어 하나도 고르고 골라 쓰는 판사의 마음을 진솔하게 써 나갔던, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 나의 애정이 담긴 그것이었다. 그러던 블로그가 어느새 13년, 사람으로 치면 중학생이 되었고 그 세월이 흐르는 동안 나는, ")])]), _c('u-tit-default', {
    staticClass: "line-height-15 font-primary mt-30px mt-md-60px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 2022년. "), _c('br'), _c('span', {
    staticClass: "primary--text text--lighten-3"
  }, [_vm._v("법복을 벗고 새로운 삶을 시작하였다.")])])], 1), _c('page-section-primary', {
    staticClass: "page-section--last",
    staticStyle: {
      "background-image": "url(/images/sub/rev/intro/intro-bg.jpg)"
    }
  }, [_c('v-sheet', [_c('div', {
    staticClass: "pa-20px px-md-0 py-md-30px py-lg-60px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mt-20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('v-img', {
    staticClass: "ml-auto",
    attrs: {
      "data-aos": "zoom-out",
      "src": "/images/sub/rev/intro/intro-img4.svg",
      "max-width": "586",
      "aspect-ratio": 586 / 406
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "pr-md-30px pr-lg-80px"
  }, [_c('u-txt-default', {
    staticClass: "line-height-17",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('p', [_vm._v(" 판사의 마음으로 오랜 시간 생활해 온 나에게 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 누군가가 변호사가 된 나의 마음이 얼마나 달라졌느냐고 묻는다면, ")]), _c('p', [_vm._v(" 이렇게 대답해주고 싶다. ")]), _c('p', [_vm._v(" 내가 가졌던 판사의 마음은 다른 그 누구도 아닌, “나의 판사의 마음”이었고, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 내 마음 속의 “나만의 판사의 마음”이 얼마나 살아있는지 나 또한 궁금하다고, ")])]), _c('u-tit-default', {
    staticClass: "line-height-15 font-primary mt-30px mt-md-60px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 그런 마음으로 나의 사무실 “판사의 마음” "), _c('br'), _c('span', {
    staticClass: "primary--text text--lighten-3"
  }, [_vm._v("즉, 판심을 연다.")])])], 1)])], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }