<template>
    <app-primary v-if="currentBusiness.code">
        <template #contentsHead>
            <sub-head-primary itemTitle="담당업무" :tabActive="!$vuetify.breakpoint.mdAndUp ? currentBusiness.title : ''">
                <template #titHead>B</template>
                <template #tit>usiness</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <v-row class="row--xl">
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="auto">
                    <v-list class="v-list--side">
                        <v-list-item v-for="item in business" :key="item.title" :to="'/business/'+item.code">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" md="" class="business-contents">
                    <div class="overflow-hidden">
                        <div data-aos="zoom-out" class="image-card d-flex align-end h-120px h-md-240px"
                        :style="'background-image: url(/images/sub/business/'+currentBusiness.code+'/banner.jpg);'">
                            <v-img data-aos="fade-up" data-aos-delay="400" :src="'/images/sub/business/'+currentBusiness.code+'/text-bg.svg'" :aspect-ratio="980 / 128" class="w-100" />
                        </div>
                    </div>
                    <dialog-primary v-for="(item, index) in currentBusiness.category" :key="index" :btnAttrs="{ color: 'grey lighten-3' }" dark max-width="960">
                        <template #activator="{ attrs, on }">
                            <v-card v-bind="attrs" v-on="on" tile flat class="business-item plus-btn-card mt-12px mt-md-24px">
                                <div class="business-item__inner py-16px py-md-32px">
                                    <v-row align="center" class="row--lg">
                                        <v-col cols="12" md="auto">
                                            <v-row data-aos="fade-up" class="row--sm">
                                                <v-col cols="auto">
                                                    <icon-double-quotes class="primary--text text--lighten-3" />
                                                </v-col>
                                                <v-col cols="auto">
                                                    <div class="w-md-140px">
                                                        <u-tit-default class="tit--sm font-primary"><span v-html="item.title"></span></u-tit-default>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="">
                                            <u-txt-default data-aos="fade-up" data-aos-delay="200" class="line-height-16">
                                                <p v-html="item.txt" class="white-nowrap"></p>
                                            </u-txt-default>
                                        </v-col>
                                        <v-col cols="12" md="auto">
                                            <div data-aos="fade-up" data-aos-delay="400">
                                                <icon-plus />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </template>

                        <template #dialogHead>
                            <div class="image-card w-100 d-flex flex-column align-center justify-center text-center pa-16px py-lg-0 min-h-120px min-h-md-140px"
                            :style="index == 0 ? 'background-image: url(/images/sub/business/'+currentBusiness.code+'/dialog-banner.jpg);'
                            : 'background-image: url(/images/sub/business/'+currentBusiness.code+'/dialog-banner'+(index + 1)+'.jpg);'">
                                <u-tit-default class="font-size-md-28 line-height-14">
                                    <span v-html="item.title" class="white-nowrap"></span>
                                </u-tit-default>
                                <u-txt-default class="grey--text text--lighten-3 line-height-17 mt-4px mt-md-8px">
                                    <p v-html="item.txt"></p>
                                </u-txt-default>
                            </div>
                        </template>
                        <u-txt-default v-if="item.addTxt" class="primary--text mb-12px mb-md-24px">
                            <p class="font-weight-bold text-center">{{item.addTxt.title}}</p>
                            <template v-if="item.addTxt.txt">
                                <div v-for="(detail, index) in item.addTxt.txt" :key="index" class="pa-16px px-md-24px grey darken-3 rounded-pill mt-12px mt-md-24px" :class="index !== 0 ? 'mt-8px mt-md-16px' : ''">
                                    <p class="line-height-15">{{detail}}</p>
                                </div>
                            </template>
                        </u-txt-default>
                        <v-img v-if="item.stepHeight" max-width="840" :aspect-ratio="840 / item.stepHeight" class="mb-12px mb-md-24px"
                        :src="index == 0 ? '/images/sub/business/'+currentBusiness.code+'/step.svg'
                        : '/images/sub/business/'+currentBusiness.code+'/step'+(index + 1)+'.svg'" />
                        <div class="primary darken-1 rounded-pill px-16px py-8px">
                            <u-txt-default class="txt--dark line-height-15 text-center"><strong>판심의 조력</strong></u-txt-default>
                        </div>
                        <u-txt-default>
                            <card-primary v-for="item in item.info" :key="item.title" dark color="grey darken-3" class="mt-12px mt-md-24px">
                                <div class="pa-16px pa-md-32px">
                                    <card-primary color="rgba(233, 217, 205, .1)">
                                        <div class="pa-4px">
                                            <strong class="primary--text">{{item.title}}</strong>
                                        </div>
                                    </card-primary>
                                    <p v-html="item.txt" class="line-height-17 mt-8px mt-md-16px"></p>
                                </div>
                            </card-primary>
                        </u-txt-default>
                    </dialog-primary>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import IconForward from "@/components/publish/styles/icons/icon-forward.vue";
import IconDoubleQuotes from "@/components/publish/styles/icons/icon-double-quotes.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";

export default {
    props: {
    },
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        IconForward,
        IconDoubleQuotes,
        IconPlus,
        DialogPrimary,
        CardPrimary,
    },
    data() {
        return { 
            business: [
                {
                    title: "이혼",
                    code: "divorce",
                    category: [
                        {
                            title: "협의이혼이란?",
                            txt: "협의이혼이란 부부가 서로 합의해서 이혼하는 것을 말합니다. 협의이혼의 경우 이혼숙려기간을 거쳐야 하므로<br class='d-none d-lg-block' /> 미성년 자녀 유무에 따라 통상 1~3개월 정도 소요됩니다.",
                            stepHeight: 178,
                            info: [
                                {
                                    title: "1. 협의상 이혼에서도 '위자료'와 '재산분할'은 놓치지 말아야 합니다.",
                                    txt: "협의이혼을 진행하시다가 간혹 '위자료'와 '재산분할'에 관하여 합의를 하지 않거나, 불리하게 합의하는 경우가 있습니다. 만약 '위자료'와 '재산분할' 합의 없이 협의 이혼이 된 경우에는 별도의 '위자료','재산분할'을 청구하여 이혼 후 새로운 출발에 필요한 재정을 확보하여야 합니다. '위자료','재산분할'은 이혼 이후 새로운 삶의 질을 결정짓는 가장 중요한 문제입니다. 판심 법무법인에서는 법률적 지원을 통해 '위자료'와 '재산분할'에서 최선의 결과를 받을 수 있도록 법률적 조력을 제공합니다.",
                                },
                                {
                                    title: "2. 미성년인 자녀가 있는 경우에는 양육자, 양육비, 면접교섭권 등 자녀의 양육에 관한 사항도 꼼꼼히 체크합니다.",
                                    txt: "미성년 자녀가 있는 경우 부부는 합의해서 양육자, 양육비, 면접교섭권 등 자녀의 양육에 관한 사항을 결정해야 합니다. 합의가 없을 경우, 가정법원이 직권으로 또는 당사자의 청구에 따라 양육에 관한 사항을 결정합니다. 이때, 양육에 관한 충분한 법리적 주장을 하지 못하여 양육권을 상실하게 되는 경우가 발생합니다. 판심 법무법인은 이혼, 양육권, 재산분할, 위자료에 때로는 상간소송까지 최소 4명의 변호사가 한팀을 이루어 한번에 진행하기 때문에 법률적으로 빈틈없는 자문이 가능합니다.",
                                },
                                {
                                    title: "3. 협의이혼 숙려기간 중 발생할 수 있는 고통을 덜어드립니다.",
                                    txt: "협의이혼 숙려기간동안 부부관계가 유지됨으로 인해 가정폭력 등으로 참을 수 없는 고통을 겪는 분들이 계십니다. 판심 법무법인은 가정폭력등으로 인해 빠르게 이혼을 원하시는 분들을 위해 숙려기간 단축 또는 면제를 위한 법률적 지원을 제공합니다.",
                                },
                            ],
                        },
                        {
                            title: "조정이혼이란?",
                            txt: "조정이혼은 재판상 이혼의 일종으로 부부 양 당사자가 이혼의사, 양육권, 양육비, 재산분할 등에 대하여 다툼이 있는 경우,<br class='d-none d-lg-block' /> 조정위원 또는 판사가 타협점을 제시하고 양 당사자의 합의를 이끌어 조정조서를 작성함으로써 이혼의 효력이 발생하는 것을 의미합니다. ",
                            stepHeight: 178,
                            info: [
                                {
                                    title: "1. 합의 전문 변호사가 직접 조정절차에 참석합니다.",
                                    txt: "조정절차의 경우, 당사자 본인이 직접 참석하여 협의 과정을 거치셔야합니다. 합의에 자신이 없으신 분, 배우자와 법원에서 대면하기 싫으신 분들의 경우에 합의 전문 변호사가 의뢰인을 대리하여 협의를 이끌어 드립니다. 다년간의 합의 경험을 바탕으로 의뢰인의 입장에 따라 최선의 결과를 도출해 냅니다.",
                                },
                                {
                                    title: "2. 조정 단계부터 이혼소송을 위한 철저한 준비 태세에 돌입합니다.",
                                    txt: "조정이혼은 잘 합의되면 빠르게 이혼을 성사시킨다는 데 장점이 있지만, 조정이 불성립된다면 아무런 준비 없이 이혼 소송 절차로 넘어가게 됩니다. 따라서 판심 법무법인은 조정단계에서부터 이혼 소송에서 다투어야 할 법적 쟁점들을 미리 확인하고, 입증자료를 준비하는 등 소송절차에 대비합니다.",
                                },
                            ],
                        },
                        {
                            title: "이혼소송이란?",
                            txt: "이혼소송은 협의와 조정이 이루어지지 않는 경우, 법원에 이혼을 청구하여 판결로써 이혼의 효력을 받는 절차를 말합니다.<br class='d-none d-lg-block' /> 이혼 사유가 없어도 쌍방의 합의를 통해 이혼이 가능한 협의이혼과는 달리 재판상이혼은 민법 제840조에서 정하는<br class='d-none d-lg-block' /> 이혼사유에 해당되어야 이혼청구가 가능합니다.",
                            addTxt: {
                                title: "재판상 이혼원인(민법 제 840조)",
                                txt: [
                                    "01. 배우자에게 부정한 행위가 있었을 때",
                                    "02. 배우자가 악의로 다른 일방을 유기한 때",
                                    "03. 배우자 또는 그 직계존속으로부터 심히 부당한 대우를 받았을 때",
                                    "04. 자기의 직계존속이 배우자로부터 심히 부당한 대우를 받았을때",
                                    "05. 배우자의 생사가 3년 이상 분명하지 아니한때",
                                    "06. 기타 혼인을 계속하기 어려운 중대한 사유가 있을때",
                                ],
                            },
                            stepHeight: 396,
                            info: [
                                {
                                    title: "1. 사전처분, 보전처분 등 선제적, 능동적인 법률상 조력을 제공합니다.",
                                    txt: "힘들게 자신에게 필요한 사전처분, 보전처분을 공부하지 않으셔도 됩니다. 판심 법무법인은 마치 내 일처럼 의뢰인분께 필요한 법률적 지원을 선제적으로 검토합니다. 재판이혼의 결과가 나오기까지 양육비, 부양료, 유아인도, 접근금지, 면접교섭 등 사전처분을 통해 이혼판결이 나오는 그날까지 의뢰인님의 일상을 보호합니다. ",
                                },
                                {
                                    title: "2. 1:1 맞춤형 소송 전략을 통해 이혼소송을 성공적으로 이끕니다.",
                                    txt: "이혼소송이야말로 1:1 맞춤형 소송 전략이 필요합니다. 부부마다 사연이 다르듯이 이혼소송에서 유리한 지점이 있고, 불리한 지점이 있기 마련입니다. 판심 법무법인은 혼인 과정 중 유리한 단서가 될 만한 사건, 증거들을 최소 4명의 변호사가 4단계에 걸쳐 꼼꼼히 체크하여 법리적으로 정돈된 서면을 작성하여 이혼소송을 성공적으로 이끕니다.  ",
                                },
                            ],
                        },
                        {
                            title: "양육권 지정 심판청구란?",
                            txt: "이혼소송은 협의와 조정이 이루어지지 않는 경우, 법원에 이혼을 청구하여 판결로써 이혼의 효력을 받는 절차를 말합니다.<br class='d-none d-lg-block' /> 이혼 사유가 없어도 쌍방의 합의를 통해 이혼이 가능한 협의이혼과는 달리 재판상이혼은 민법 제840조에서 정하는 이혼사유에 해당되어야<br class='d-none d-lg-block' /> 이혼청구가 가능합니다.",
                            stepHeight: 178,
                            info: [
                                {
                                    title: "1. 적극적인 사전처분을 진행합니다.",
                                    txt: "법원은 양육자를 지정함에 있어서 &quot;자녀의 복리&quot;,&quot;현재의 양육상태 유지&quot;를 중요한 요소로 고려합니다. 따라서 이혼소송중 비양육자는 향후 양육권 다툼에서 불리한 위치에 놓이게 됩니다. 또한, 자녀의 양육에 관한 처분의 이행확보수단 차원에서도 사전처분을 적절히 활용하는 것이 중요합니다. 판심 법무법인은 유아인도, 임시양육자지정과 같은 적극적인 사전처분제도를 활용하여 유아탈취가 우려되는 등 양육권 다툼이 격해질 것을 대비하여 선제적으로 대응합니다.",
                                },
                                {
                                    title: "2. 철저한 가사조사절차 대비합니다.",
                                    txt: "이혼소송 중 양육권 다툼이 있는 경우, 가사조사관들이 양육권판단에 기준이 되는 요소들을 조사하게 됩니다.  가사조사관이 작성한 보고서는 객관적인 보고서라는 점에서 재판부가 크게 신뢰하므로 향후 양육자로서 선정되기 위해서는 가사조사관들의 조사 기준들을 잘 숙지하고 준비하는 것이 중요합니다. 판심 법무법인은 자체적인 가사조사 대응 시뮬레이션을 진행하고 있으므로 이를 통해 의뢰인분들이 가사조사절차에서 막힘없이 대응 할 수 있도록 지원합니다.",
                                },
                            ],
                        },
                        {
                            title: "재산분할이란?",
                            txt: "혼인기간 동안 부부가 협력하여 이룬 재산에 대하여 분할을 청구할 수 있습니다.<br class='d-none d-lg-block' /> 이혼소송과 함께 병합하여 이루어지는 경우가 많으나, 이혼 후 2년 내에 재산분할청구를 하여야 합니다.",
                            info: [
                                {
                                    title: "1. 재산분할 대상 선정이 가장 중요합니다.",
                                    txt: "재산분할은 기여도를 산정하여 재산분할대상 재산 전체에 대하여 산정하는 방식으로 진행되므로, 재산분할 대상을 선정할 때부터 법률적인 조력이 필요합니다.  부부 일방이 혼인 전에 취득하여 소유하고 있던 재산이나, 혼인 중이라도 쌍방의 협력과는 관계없이 부부 일방이 상속, 증여 등에 의하여 취득한 이른바 특유재산은 원칙적으로 재산분할의 대상에서 제외됩니다."
                                },
                                {
                                    title: "2. 대상이 정해졌다면, 상대방의 재산은닉 및 처분을 방지합니다.",
                                    txt: "부부가 협력하여 이룬 재산이 상대방의 명의로 되어있는 경우 이혼소송을 준비하는 과정에서 상대방 재산을 처분, 은닉할 가능성이 있습니다.  판심 법무법인은 가압류 등 선제적인 보전처분을 통하여 상대방의 재산처분을 막고, 재산명시제도를 적극활용하여 숨겨져 있는 상대방의 재산을 찾아내어 원활한 재산분할절차를 지원합니다."
                                },
                                {
                                    title: "3. '얼마'를 나눌지, '어떻게' 나눌지를 종합적으로 판단하여야 합니다.",
                                    txt: "당사자 일방이 사업체를 운영하고 있다면, 경영권 보호 차원에서 현금을 지급하는 방식으로 재산분할을 해야하는지 여부를 면밀히 검토하여야 합니다. 아울러 가치의 변동이 있는 자산을 보유하고 있다면, 향후 그 자산의 가치가 어떻게 변화하는지를 검토하여야 보다 유리한 재산분할결과를 이끌어낼 수 있습니다.  판심 법무법인은 단순히 재산목록을 정리하는것에서 그치지 않고, 어떠한 방법이 의뢰인님께 최선의 결과일지 검토하여 의뢰인님께 선택지를 제공하는 서비스를 제공하고 있습니다."
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "상간자 소송",
                    code: "fornication",
                    category: [
                        {
                            title: "상간자소송<br class='d-none d-md-block' />이란?",
                            txt: "부부 일방이 불륜행위를 저지른 경우, 다른 일방이 받은 정신적 고통에 대한 손해 배상을 청구하는 소송을 말합니다.<br class='d-none d-lg-block' /> 다른 부부 일방에 청구하거나 불륜행위의 상대방에 대하여 청구할 수 있습니다.",
                            stepHeight: 154,
                            info: [
                                {
                                    title: "1.  오히려 역공당할 수 있는 상황을 방지합니다.",
                                    txt: "상간자소송의 경우 감정적인 대응을 하는 경우가 많아, 스토킹범죄 등으로 상간자로부터 오히려 고소를 당하는 경우가 발생합니다. 판심 법무법인은 의뢰인분께 법률적인 가이드라인을 제공하여 증거 수집 과정 등에서 발생할 수 있는 위험들을 사전에 방지합니다.",
                                },
                                {
                                    title: "2. 최대의 위자료 액수로 승소합니다.",
                                    txt: "상간자소송의 경우, 의뢰인분이 받은 정신적 고통에 비해 지나치게 경미한 액수의 판결이 나오는 것이 현실입니다. 판심 법무법인은 그간 상간자소송을 진행하면서 위자료액수를 늘릴 수 있는 핵심 노하우들을 보유하고 있어 이를 적극 활용하여 최대한 높은 위자료 액수의 판결문을 받으시도록 지원합니다.",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "상속",
                    code: "inheritance",
                    category: [
                        {
                            title: "상속재산분할<br class='d-none d-md-block' />심판청구란?",
                            txt: "망인이 적법한 유언 없이 사망하면 상속인들은 협의를 통해 상속재산을 나누어 가지게 됩니다.<br class='d-none d-lg-block' /> 그러나 상속인 간 분할의 협의가 이루어지지 아니하거나 분할의 협의가 이루어질 수 없는 때에는 각 상속인은<br class='d-none d-lg-block' /> 가정법원에 그 분할을 청구할 수 있는데, 이를 심판에 의한 상속재산분할이라고 합니다. ",
                            stepHeight: 202,
                            info: [
                                {
                                    title: "1. 합의 전문 변호사가 직접 조정절차에 참석합니다.",
                                    txt: "심판을 청구하면 법원은 일단 조정에 회부하여 당사자들 간의 합의를 도출하는 절차를 거치게 됩니다. 만약 조정이 성립된다면 해당 절차에서 심판은 종료된 것이지만, 조정이 성립되지 않는다면 정식 심판 절차가 진행되게 됩니다. 판심 법무법인은 합의 전문 변호사가 의뢰인을 대리하여 조정절차에서의 합의를 이끌어 드립니다. ",
                                },
                                {
                                    title: "2. 신속하게 상속관계를 정리합니다.",
                                    txt: "재산분할이 지연되면, 상속세와 취득세 신고기한이 경과하여 가산세가 부과되는 등 비용적인 손해가 발생합니다. 판심 법무법인은 신속한 소장 접수 및 소송 진행으로 빠르게 심판 결과를 도출해 내며, 다른 공동상속인이 이미 분할하거나 처분한 재산에 대하여 상속분가액상당지급청구를 하는 등 빠르게 재산 관계를 정리합니다.  ",
                                },
                            ],
                        },
                        {
                            title: "유류분반환<br class='d-none d-md-block' />청구란?",
                            txt: "민법은 피상속인의 유증이나 증여가 있더라도 상속재산 중 최소한의 법정 비율은 상속인에게 유보되어야 하는데,<br class='d-none d-lg-block' /> 이처럼 상속재산 중 상속인에게 유보되는 최소한의 몫을 유류분이라고 합니다.",
                            stepHeight: 154,
                            info: [
                                {
                                    title: "1. 유류분반환청구권 행사 시 가장 중요한 것은 기한입니다.",
                                    txt: "유류분청구권은 유류분 권리자가 상속의 개시와 반환해야 할 증여 또는 유증을 한 사실을 안 때부터 1년 이내에 하지 않으면 시효에 의하여 소멸합니다. 상속이 개시된 때부터 10년이 경과된 때에도 시효에 의해 소멸합니다. 대부분 법률적 쟁점이 되는 유류분소멸시효는 유류분 침해 사실을 안 날로부터 1년이라는 단기시효입니다. 고인의 명복을 빌어주기에도 짧은 기간이기에 기한을 놓치는 사례가 많습니다. 판심 법무법인은 신속하게 유류분소송을 제기한 후 법원에 사실조회신청을 하여 피상속인의 금융거래내역 등 상속재산을 조회하여 유류분을 산정합니다. ",
                                },
                                {
                                    title: "2. 유류분반환청구 산정을 제대로, 정확하게 합니다.",
                                    txt: "유류분은 법정상속분에 대한 일정비율이지만, 유류분의 기초가 되는 재산은 상속재산과 동일하지는 않습니다. 유류분 산정의 기초재산은 상속재산의 가액에 상속개시 1년 이내의 증여재산의 가액을 가산하고 채무의 전액을 공제하여 산정합니다. 얼핏 보면 간단해 보이는 계산법이지만, 판심 법무법인은 당사자 쌍방이 유류분권리자에 손해를 가할 것을 알고 한 증여인지 여부, 기초재산 중 적극재산이 소극재산과 같거나 적지만, 제3자에 대한 증여가 있는 경우 등 다양한 법리적 관점에서 유류분권리자 별 유류분 계산을 정확하게 해냅니다.",
                                },
                            ],
                        },
                        {
                            title: "상속회복청구<br class='d-none d-md-block' />란?",
                            txt: "상속회복청구권이란 상속인이 아닌 자가 상속인이라고 참칭하여 진정한 상속권자의 상속권이<br class='d-none d-lg-block' /> 참칭상속권자로 인하여 침해된 경우, 상속권자 또는 그 법정대리인이 그 침해의 회복을 위해 갖게 되는 청구권을 말합니다.",
                            stepHeight: 154,
                            info: [
                                {
                                    title: "1. 법률적으로 정확한 상속권을 파악하여 침해여부를 판단해 드립니다.",
                                    txt: "상속권의 “침해사실을 안 날”로부터 3년, “침해행위가 있었던 날”로부터 10년의 제척기간을 가지며,그 전에 소송을 제기해야 합니다. 많은 분들이 자신의 상속분이 조금 적다는 것을 알면서도 상속권 침해가 되었는지 판단을 하지 못하여 머뭇거리다가 결국 제척기간이 경과하여 자신의 상속권을 회복하지 못하는 경우가 발생합니다. 판심 법무법인은 법률적으로 정확한 상속권을 파악하여 침해여부를 판단하므로 내 상속분이 부족하다는 생각이 드시는 경우에는 빠르게 상담받아 상속회복청구가 가능한지 확인한 후 진정한 상속인으로서의 권리를 되찾아야 합니다. ",
                                },
                                {
                                    title: "2. 상속회복청구권은 그 상대방을 정확히 특정하는 것이 중요합니다.",
                                    txt: "다른 공동상속인이나 참칭상속인으로부터 상속재산을 취득한 사람도 상속회복청구의 상대방이 될 수 있습니다. 많은 분들이 다른 공동상속인이나 참칭상속인으로부터 상속재산을 취득한 사람도 상속회복청구의 상대방이 될 수 있다는 사실을 모른채 침해를 방치하다가 역시 제척기간을 경과하여 자신의 상속권을 회복하지 못하는 경우가 많습니다. 판심 법무법인은 공동상속인, 후순위상속인, 상속결격자, 무효혼인의 배우자, 허위의 기재로 호적상 자녀로 올라가 있는 사람, 문단으로 상속재산의 전부나 일부를 점유하고 있는 사람, 참칭상속인으로부터 법률행위 그 밖에 계약에 따라 상속재산을 취득한 사람 등 그 상대방을 법률적으로 정확히 특정하여 소송을 제기하여 승소합니다.",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "위자료",
                    code: "alimony",
                    category: [
                        {
                            title: "위자료란?",
                            txt: "위자료란 혼인 파탄의 책임이 있는 배우자 또는 상간남녀에게 청구하는 '정신적 고통에 대한 손해배상'을 의미합니다. ",
                            addTxt: {
                                title: "이혼소송 위자료 판단 기준",
                            },
                            stepHeight: 160,
                            info: [
                                {
                                    title: "1. 위자료 금액 예측 서비스를 제공합니다.",
                                    txt: "위자료는 사실관계 및 재판부의 재량에 따라 1,000만 원 ~ 5,000만 원 사이의 금액에서 결정되는 편입니다. 판심 법무법인은 1차 상담내용과 그간의 소송경험을 토대로 위자료 금액을 예측하여 소송진행여부 및 청구금액을 즉시 안내해드립니다.",
                                },
                                {
                                    title: "2. 높은 위자료를 받기 위하여 1:1 변호사 매칭 서비스로 면밀한 사건 검토를 진행합니다. ",
                                    txt: "높은 위자료를 받기 위해서는 스토리텔링(storytelling)이 중요합니다. 상대방의 유책사유와 상대방으로부터 어떠한 피해를 입었는지를 논리적으로 설명해내야합니다. 판심 법무법인은 '위자료'의 경우 혼인생활 전반에 대한 사실관계 파악이 무엇보다 중요함을 알기에 1:1 변호사 매칭 상담서비스를 통해 추가적인 상담진행을 통하여 위자료 액수를 높일 수 있는 포인트를 찾아냅니다. 의뢰인님과의 적극적인 소통은 위자료소송 승소의 밑바탕이 됩니다. ",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "조세",
                    code: "tax",
                    category: [
                        {
                            title: "조세란?",
                            txt: "상속, 가업승계 및 이혼 재산분할의 경우 조세 쟁점이 발생할 수 있습니다. ",
                            stepHeight: 512,
                            info: [
                                {
                                    title: "1. 세법, 회사법, 가족법, 형사법등 다양한 전문가들의 협업을 통한 one-stop 서비스를 제공합니다.",
                                    txt: "상속 및 가업승계 업무는 절세나 기업 구조조정 외에도 형사상 배임 등 오너 리스크 방어가 함께 면밀히 검토되어야 합니다. 따라서 판심 법무법인은 고문회계사, 검사출신변호사, 판사출신변호사 등 다양한 전문가들이 하나의 사건을 위하여 하나의 팀을 구성합니다. 전문가들은 협업을 통하여 종합적 관점에서 대책을 제시하여 안정적인 상속 및 가업승계와 관련된 소송, 자문, 리스크관리까지 one-stop 서비스를 제공합니다.",
                                },
                                {
                                    title: "2. 사건 맞춤형 법률서비스를 제공합니다.",
                                    txt: "의뢰인 상황에 맞는 절세방법부터 의뢰인 상황에 맞는 세금 납부방법 및 상속 이후 재산활용계획까지 판심 법무법인은 개인맞춤형 법률서비스를 제공하고 있습니다. 해외거주자, 재상속, 상속분쟁상황, 배우자 공제 등 개인별로 가지고 있는 법률문제는 하나의 서비스로 묶을 수 없으므로 판심 법무법인은 의뢰인 1인에 4인의 전담변호사팀을 통해 맞춤형 서비스를 제공하고 있습니다. ",
                                },
                                {
                                    title: "3. 조세전략자문 서비스를 제공합니다.",
                                    txt: "조세는 현재 얼마나 절세할 수 있는지도 중요하지만 장래 어떻게 리스크를 감소하고, 분쟁을 피할지 '조세 전략'을 세우는 것이 중요합니다. 판심 법무법인은 세무/회계 이슈파악과 검토 및 과세기조와 동향등을 파악하여 한 단계 앞서 가는 조세자문 서비스를 제공하고 있습니다.",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "사실혼",
                    code: "commonlaw",
                    category: [
                        {
                            title: "사실혼이란?",
                            txt: "사실혼이란 실질적으로 부부로써 혼인생활을 하였으나, 단지 혼인신고만을 하지 않은 경우를 의미합니다. <br class='d-none d-md-block' />사실혼의 경우라 하더라도 법률혼에 준하여 일정 부분 보호받을 수 있으며, 결혼식을 하지 않았더라도 사실혼으로 인정될 수 있습니다. ",
                            addTxt: {
                                title: "사실혼인지 여부를 판단하는 기준 : 주관적 혼인의사의 합치 + 객관적 혼인생활의 실체",
                            },
                            stepHeight: 160,
                            info: [
                                {
                                    title: "1. 사실혼 입증하기",
                                    txt: "사실혼은 법률혼과는 달리 별도의 이혼절차 없이 당사자간의 합의 또는 일방의 통보만으로도 해소될 수 있습니다. 따라서 '사실혼'을 인정받아 재산분할, 위자료청구 등 권리를 인정받기 위해서는 사실혼관계에 있었음을 입증하여야 합니다. 판심 법무법인은 다수의 사실혼 입증경험을 통하여 작성한 '사실혼 판단 리스트'를 통하여 1차 상담시 사실혼 입증 여부를 판단합니다. ",
                                },
                                {
                                    title: "2. 사실혼관계가 해소되었을 경우, '재산분할', '위자료청구'가 가능합니다.",
                                    txt: "사실혼관계에 있었던 경우뿐만 아니라 법률상 혼인관계의 경우에도 혼인신고 전 동거기간이 있었던 경우와 같이 사실혼관계 기간에 따라 추가적인 '재산분할' 및 '위자료청구'를 하는 경우가 발생합니다. 사실혼관계의 경우에도 부부간의 의무가 인정되며, 사실혼관계가 해소되는 경우 '재산분할', '위자료청구'가 가능하므로 판심 법무법인은 재산분할 및 위자료청구에 있어서도 사실혼관계를 포함하여 의뢰인님께 최선의 결과를 만들어냅니다.",
                                },
                                {
                                    title: "3. 사실혼부부의 자녀 문제도 함께 해결합니다.",
                                    txt: "판심 법무법인은 사실혼관계에서 태어난 자녀가 있는 경우 친권자 및 양육자 지정, 인지청구, 양육비청구 등 one-stop서비스를 제공합니다. ",
                                },
                            ],
                        },
                    ],
                },
            ],
            currentBusiness: {},
        }
    },     
    created() {
        this.updateCurrentBusiness();
    },
    watch: {
        $route(newRoute, oldRoute) {
            this.updateCurrentBusiness();
        },
    },
    methods: {
        updateCurrentBusiness() {
            const code = this.$route.params.code;
            this.currentBusiness = this.business.find(item => item.code === code) || {};
        },
    },
};
</script>

<style lang="scss" scoped>
.v-list{
    padding: 0;
    width: 160px;
}
.v-list-item{
    padding: 0;
    min-height: 60px;
    &::before,
    &::after{
        display: none;
    }
    &__content{
        flex-wrap: nowrap;
        &::after{
            content: "";   
            height: 12px;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            margin-left: 8px;
            background-image: url(/images/icon/list-active.svg);
            background-position: right 1px center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            opacity: 0;
            visibility: hidden;
            transition: .3s ease-out;
        }
    }
    &__title{
        font-size: 1.8rem;
        color: var(--v-grey-lighten1);
        transition: .3s ease-out;        
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    &--active{
        .v-list-item__content{
            &::after{
                opacity: 1;
                visibility: visible;
            }
        }
        .v-list-item__title{
            color: var(--v-primary-base);
            font-weight: 700;
        }
    }
}
.business-item{
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
    padding: 0;
    transition: .3s ease-out;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/images/sub/business/business-active.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        visibility: hidden;
        transition: .3s ease-out;
    }
    &__inner{
        position: relative;
        z-index: 1;
        >.row--lg,
        >.row--lg>[class*=col]{
            transition: .3s ease-out;
        }
        ::v-deep{
            .icon-plus{
                color: var(--v-grey-base);
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){    
    .business-contents{
        max-width: calc(100% - 220px);
    }
    .white-nowrap{
        ::v-deep{
            >br.d-md-block{                
                display: none !important;
            }
        }
    }
}
@media (min-width:1024px){
    .v-list-item:hover{
        .v-list-item__title{
            color: var(--v-primary-base);
            font-weight: 700;
        }
    }
    .business-item:hover{
        --grid-gutter-lg: 8px;
        padding: 0 24px;
        border-color: #fff;
        &::before{
            opacity: 1;
            visibility: visible;
        }
        *:not(.icon-double-quotes){
            color: #fff;
        }
    }
    .white-nowrap{
        ::v-deep{
            >br.d-lg-block{                
                display: none !important;
            }
        }
    }
}
@media (min-width:1200px){
    .white-nowrap{
        ::v-deep{
            >br.d-xl-block{                
                display: none !important;
            }
        }    
    }
}

</style>
