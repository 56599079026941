var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "심리안정프로그램"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("P")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("rogram")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first"
  }, [_c('div', {
    staticClass: "image-card pa-20px pa-md-30px px-lg-0 py-lg-88px",
    staticStyle: {
      "background-image": "url(/images/sub/program/program-bg.jpg)"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": ""
    }
  }, [_c('div', {
    staticClass: "pl-lg-48px"
  }, [_c('u-tit-default', {
    staticClass: "line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 판심 법무법인은 오롯이 의뢰인의 행복한 삶을 위한 종합 법률서비스를 제공하고 있습니다. ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto",
      "offset": "6",
      "offset-md": "0"
    }
  }, [_c('div', {
    staticClass: "w-100 pr-lg-48px"
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "data-aos": "zoom-out",
      "src": "/images/sub/program/program-bg2.svg",
      "max-width": "470",
      "width": _vm.$vuetify.breakpoint.lgAndUp ? 470 : '',
      "aspect-ratio": 470 / 324
    }
  })], 1)])], 1)], 1), _c('u-txt-default', {
    staticClass: "line-height-17 pt-12px pt-md-24px"
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 판심이 수많은 이혼소송을 경험하면서 느낀 결론은, 의뢰인님의 행복을 위해서는 법률적 조력도 필요하지만, "), _c('span', {
    staticClass: "grey--text text--darken-4"
  }, [_vm._v("이혼을 결심하시기까지 받은 정신적 고통을 치유하고, 자녀들이 상처받지 않도록 정서적으로 보호하는 일 또한 매우 중요하다는 것입니다.")])]), _c('p', {
    staticClass: "mt-12px mt-md-24px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 따라서 판심 법무법인은 의뢰인님과 자녀들이 "), _c('span', {
    staticClass: "grey--text text--darken-4"
  }, [_vm._v("이혼소송 전에 받았던 정서적 상처의 치유")]), _vm._v("와 "), _c('span', {
    staticClass: "grey--text text--darken-4"
  }, [_vm._v("이혼소송 중에 발생할 수 있는 정신적 고통의 예방")]), _vm._v("을 위하여 심리안정 프로그램을 마련하고 있습니다. ")])]), _c('v-col', {
    staticClass: "mt-n8px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 판심 법무법인은 '부모와 아이 마음 의원', '유앤문 정신건강의학과'와의 제휴를 통해 의뢰인과 그 자녀들을 위한 심리안정 프로그램을 운영하여 이혼소송 중에서도 정서적으로 안정된 삶을 살아가실 수 있도록 지원해드립니다. ")]), _c('u-tit-default', {
    staticClass: "tit--sm line-height-15 grey--text text--darken-4 mt-12px mt-md-24px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 판심 법무법인은 오롯이 의뢰인의 행복한 삶을 위한 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 종합 법률서비스를 제공하고 있습니다. ")])], 1)], 1)], 1)], 1), _c('page-section-primary', {
    staticClass: "pt-0 page-section--last"
  }, _vm._l(_vm.info, function (item, index) {
    return _c('div', {
      key: index,
      class: index !== 0 ? 'pt-40px pt-md-60px pt-lg-100px' : ''
    }, [_c('v-row', {
      staticClass: "image-row",
      class: index % 2 == 0 ? 'image-row--reverse' : '',
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "5"
      }
    }, [_c('div', {
      staticClass: "image-row__image"
    }, [_c('div', {
      staticClass: "image-row__image__inner"
    }, [_c('div', {
      staticClass: "image-row__image__bg",
      attrs: {
        "data-aos": "zoom-out"
      }
    }), _c('v-img', {
      staticClass: "w-100 elevation-16",
      attrs: {
        "data-aos": "zoom-out",
        "data-aos-delay": "200",
        "src": item.image,
        "aspect-ratio": 1 / 1
      }
    })], 1)])]), _c('v-col', {
      staticClass: "mt-20px mt-md-0",
      attrs: {
        "cols": "12",
        "md": "7"
      }
    }, [_c('tit-wrap-secondary', {
      scopedSlots: _vm._u([{
        key: "TxtAdd",
        fn: function () {
          return [_vm._v(_vm._s(item.txt))];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-row', {
      staticClass: "row--xs mx-md-n20px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200"
      }
    }, _vm._l(item.infoDetail, function (child, childIndex) {
      return _c('v-col', {
        key: childIndex,
        staticClass: "px-md-20px",
        class: childIndex !== 0 ? 'mt-2px mt-md-0' : '',
        attrs: {
          "cols": "12",
          "md": "6",
          "xl": "auto"
        }
      }, _vm._l(child, function (detail, detailIndex) {
        return _c('txt-dot', {
          key: detailIndex,
          class: detailIndex !== 0 ? 'mt-4px mt-md-8px' : ''
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(detail)
          }
        })]);
      }), 1);
    }), 1)], 1)], 1)], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }